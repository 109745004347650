import { useEffect, useState, useRef } from "react";
import config from "../../config/";
import "../../style/components/message/index.css";

const Message = (props: {
    content: string,
    color: string,
    setMessage: React.Dispatch<React.SetStateAction<{
        color: string;
        content: string;
    } | null>>
}) => {
    const { content, color, setMessage } = props;
    const [messageClass, setMessageClass] = useState('message')
    const timeoutTimer = useRef<null | any>(null);

    useEffect(() => {
        setTimeout(() => setMessageClass((className) => className + " active"), 0)
        timeoutTimer.current = setTimeout(() => {
            setMessageClass("message")
            setTimeout(() => {
                setMessage(null)
            }, 100);

        }, config.message_time_out);
        return () => clearTimeout(timeoutTimer.current);
    }, [setMessage]);
    return (
        <div className='container-message'>
            <div className={messageClass} style={{ color: color, border: `solid 2px ${color}`, backgroundColor: "#FFF", textAlign: "center", }}>
                <span className="w-full px-7"> {content}</span>
                <div className="cross" onClick={() => setMessage(null)}></div>
            </div>
        </div>
    );
};

export default Message;