import paths from "../paths";
const tools: Tools[] = [
    {
        action: "clear",
        html_interface: {
            title: "Are you sure ?",
            html_templates: [
                {
                    action: "clear",
                    show: true,
                    text: "yes",
                },
                {
                    action: "close",
                    show: true,
                    text: "no",
                }
            ],
            show: true,
        },
        icon: paths.clear_icon,
        show: true,
        text: "clear",
    },
    {
        action: "marker",
        active: false,
        cursor_style: "cursor-brush02",
        icon: paths.marker_icon.m02,
        show: true,
        size: 3,
        text: "brush {{br}} 0.2",
        iconPath: "./img/icons/cursor-pencil-02-1x.png"
    },
    {
        action: "marker",
        active: true,
        cursor_style: "cursor-brush03",
        icon: paths.marker_icon.m03,
        show: true,
        size: 8,
        text: "brush {{br}} 0.3",
        iconPath: "./img/icons/cursor-pencil-03-1x.png"
    },
    {
        action: "marker",
        active: false,
        cursor_style: "cursor-brush04",
        icon: paths.marker_icon.m04,
        show: true,
        size: 12,
        text: "brush {{br}} 0.3",
        iconPath: "./img/icons/cursor-pencil-04-1x.png"
    },
    {
        action: "marker",
        active: false,
        cursor_style: "cursor-brush05",
        icon: paths.marker_icon.m05,
        show: true,
        size: 18,
        text: "brush {{br}} 0.5",
        iconPath: "./img/icons/cursor-pencil-05-1x.png"

    },
    {
        action: "eraser",
        active: false,
        cursor_style: "cursor-eraser",
        icon: paths.eraser_icon,
        show: true,
        size: 30,
        text: "eraser",
        iconPath: "./img/icons/cursor-eraser-1x.png"

    },
    {
        action: "bucket",
        active: false,
        cursor_style: "cursor-bucket",
        icon: paths.bucket_icon,
        show: true,
        text: "bucket",
        iconPath: "./img/icons/cursor-bucket-1x.png"

    },
    {
        action: "addToGallery",
        icon: paths.gallery_icon,
        text: "add to gallery",

        html_interface: {
            show: true,
            title: "Add to the Pop Art gallery ?",
            html_templates: [
                {
                    action: "add",
                    show: true,
                    text: "add to gallery {{galleryName}}",
                    postUri: "./gallery/index.php",
                    galleryName: "default"
                },
                {
                    action: "fill-gallery-name",
                    postUri: "./gallery/index.php",
                    show: true,
                    text: "fill gallery name",
                },
                {
                    action: "create-gallery",
                    postUri: "./gallery/index.php",
                    show: true,
                    text: "create a new gallery"
                }
            ],
        },
        show: true,
    },
    {
        action: "link",
        _blank: true,
        href: "https://exemple.com",
        icon: paths.info_icon,
        show: true,
        text: "info"
    }
];


export default tools;
