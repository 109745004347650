import React from 'react';
import Color from './color';
import Undo from './undo';
import "../../style/components/colors-palleter/index.css"

const ColorPallete = (props: {
    onClick: onPalleteClick,
    colorIndex: number,
    config: Config
}) => {
    const { onClick, colorIndex, config } = props;
    return (
        <div className='color-pallete'>
            {config.colors_palette.map((color, index) => <Color color={color} key={`colorID:${index}`} onClick={onClick} actived={index === colorIndex} index={index} />)}
            <Undo onClick={onClick} />
        </div>
    );
};

export default ColorPallete;