import React from 'react';
import config from '../../../config';
import { LoaderFunction, redirect } from "react-router-dom"
import NotFound from '../../NotFound';
import axios from 'axios';

const Confirm = () => {
    return <NotFound />;
};

export default Confirm;
export const loader: LoaderFunction = async ({ params }) => {
    const { confirmToken } = params;
    if (!confirmToken) return null;
    try {
        const result = await axios.post(`${config.api_server_uri}/auth/confirm/${confirmToken}`);
        if (result.status !== 200) return null
        return redirect('/dashboard');
    } catch (err) {
        return null
    }
};