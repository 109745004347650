import React from 'react';

const Color = (props: {
    color: string,
    onClick: onPalleteClick,
    actived: boolean,
    index: number
}) => {
    const { color, onClick, actived, index } = props;
    return (
        <div onClick={() => { onClick(index, "color") }} >
            <button className={actived ? "active" : ""} style={{ background: color }}></button>
        </div>
    );
};

export default Color;