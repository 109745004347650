import React from 'react';
const NotFound = () => {
    return (
        <div className='h-screen w-screen bg-gray-50 dark:bg-gray-900 text-black dark:text-white flex justify-center items-center'>
            <div className="relative text-6xl flex justify-center items-center flex-col gap-6">
                <div>
                    404
                </div>
                <div>
                    Not Found
                </div>
            </div>
        </div>
    );
};

export default NotFound;